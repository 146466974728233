.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.post-image {
  width: 100%;
  border-radius: 15px;
  margin-top: 10px;
}