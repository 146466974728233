.card {
  width: 500px;
  min-height: 200px;
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.image-upload {
    width: 100%;
    margin-top: 10px;
    border-radius: 15px;
}