.post-card {
  width: 500px;
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  margin-bottom: 20px;
}

  /* .username {
    text-align: left;
    margin-left: 10px;
  } */


.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

img {
  background-color: aquamarine;
}

.post-container {
  margin-left: 10px;
}

.content {
  font-size: 14px;
  font-weight: 400;
}

.username {
  font-size: 14px;
  font-weight: 600;
}

.date {
  margin-top: 3px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
